import React from 'react'
import styled from 'styled-components'
import { Container, Row } from 'react-awesome-styled-grid'//remeber Col...may need to be fixed
import siteConfig from '../../data/siteConfig'
import { withPrefix, graphql } from "gatsby"
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'
import BlogList from '../components/bloglist'

const Layout = loadable(() => import('../components/layout'))

const Blog = ({ className, location, data }) => {
  const title = "Blog"
  const { keywords } = siteConfig
	const { edges } = data.allMarkdownRemark
  return (
    <Layout location={location}>
      <SEO
        title={title}
        keywords={keywords}
      />

      <Hero
        heroImg={withPrefix('/images/cover.jpeg')}
        title={title}
      />

      <Wrapper className={className}>
        <Container className="page-content" fluid>
          <Row>
      			{edges.map(({ node }) => (
       			 <BlogList
       			   key={node.id}
       			   cover={node.frontmatter.cover.childImageSharp.fluid}
         			 path={node.frontmatter.path}
        			 title={node.frontmatter.title}
     			     date={node.frontmatter.date}
   			       excerpt={node.excerpt}
     			   />
    			  ))}
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default styled(Blog)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }

`

Blog.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            frontmatter: PropTypes.shape({
              cover: PropTypes.object.isRequired,
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};
//(pruneLength: 200)
export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            path
            date(formatString: "MM.DD.YYYY")
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 1000
                  quality: 90
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
